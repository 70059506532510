import React from "react";
import Layout from "../components/Layout";

//   This is the main contact page

const Contact = () => {
	return (
		<Layout>
			<h1>Contact page</h1>
		</Layout>
	);
};

export default Contact;
